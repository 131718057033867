import { useMounted } from "@classdojo/web";
import { BodyText, LabelText } from "@classdojo/web/nessie";
import React, { useLayoutEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useParentSubscriptionsQuery from "src/hooks/api/parent/useParentSubscriptionsQuery";
import { Repeat } from "tabler-icons-react";
import Logo from "./Logo";
import useStyles from "./styles/Header.styles";
import { Box, Burger, ActionIcon, Avatar, Divider, Menu, ChevronDown, TimezoneText } from "UILibrary";
import useLogoutMutation from "src/hooks/api/auth/useLogoutMutation";
import { useSessionQuery } from "src/hooks/useSession";
import { actionTypes } from "old/src/store/actionTypes";
import { useGlobalStore } from "old/src/store";
import useHelperWidgetAPI from "src/hooks/useHelperWidgetAPI";
import marketplaceTheme from "src/utils/marketplaceTheme";
import { IconCalendar, IconList, IconHelpCircle, IconMail, IconPlusCircle, IconLogout } from "@web-monorepo/dds-icons";

interface ParentHeaderProps {
  navbarOpened?: boolean;
  toggleNavbar?(): void;
}

const ParentHeaderContents: React.FC<ParentHeaderProps> = ({ navbarOpened, toggleNavbar }) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const push = useNavigate();
  const burgerTitle = navbarOpened ? "Open navigation" : "Hide navigation";
  const mounted = useMounted();
  const logoutMutation = useLogoutMutation();
  const { sessionData } = useSessionQuery();
  const isBookingPage = useMemo(() => pathname.includes("booking"), [pathname]);
  const isSingleTrialPage = useMemo(() => pathname.includes("/single-class"), [pathname]);
  const isUpcomingClassesPage = useMemo(() => pathname.includes("/parents/classes"), [pathname]);
  const isPastClassesPage = useMemo(() => pathname.includes("/parents/pastClasses"), [pathname]);
  const showingNavigationTabs = isUpcomingClassesPage || isPastClassesPage;
  const [_, dispatch] = useGlobalStore();
  const shouldRenderAdditionalClassButton = !isBookingPage && !isSingleTrialPage;
  const { subscriptions } = useParentSubscriptionsQuery();
  const helperWidgetAPI = useHelperWidgetAPI();

  const shouldShowManageSubscription = Boolean(subscriptions?.length);

  // ensure scrollbar on pages with tabs, so that longer/shorter ones dont bounce with presence/absence of scrollbar
  // say, for instance, users with current classes but no past classes
  useLayoutEffect(() => {
    if (showingNavigationTabs && document.scrollingElement && document.scrollingElement instanceof HTMLElement) {
      const scrollingElement = document.scrollingElement;

      scrollingElement.style.overflowY = "scroll";

      return () => {
        scrollingElement.style.overflowY = "";
      };
    }
  }, [showingNavigationTabs]);

  if (!mounted) return null;

  const navigateToPastClasses = () => push("/parents/pastClasses");
  const navigateToUpcomingClasses = () => push("/parents/classes");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <Logo href="/parents/classes" sx={{ width: "150px" }} noText />
      {showingNavigationTabs && (
        <div sx={{ display: "flex", height: "100%" }}>
          <NavTab copy="Upcoming" selected={isUpcomingClassesPage} handleClick={navigateToUpcomingClasses} />
          <NavTab copy="Past classes" selected={isPastClassesPage} handleClick={navigateToPastClasses} />
        </div>
      )}

      <Box className={classes.headerActions}>
        <Menu>
          <Menu.Target>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Avatar
                src={null}
                alt="profile"
                radius="xl"
                mr={8.5}
                sx={
                  {
                    div: {
                      color: marketplaceTheme.colors.dt_content_accent,
                      backgroundColor: marketplaceTheme.colors.dt_background_accent,
                    },
                  } as any
                }
              >
                {sessionData?.user_details.email.charAt(0).toUpperCase()}
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <BodyText level={2}>
                  {sessionData?.user_details.first_name} {sessionData?.user_details.last_name}
                </BodyText>
                <TimezoneText />
              </Box>
              {!isBookingPage && (
                <ActionIcon ml={16}>
                  <ChevronDown
                    sx={{ cursor: "pointer" }}
                    data-testid="dropdownLink"
                    color={marketplaceTheme.colors.dt_content_tertiary}
                    size={18}
                  />
                </ActionIcon>
              )}
            </Box>
          </Menu.Target>

          <Menu.Dropdown sx={{ minWidth: "165px" }}>
            {!showingNavigationTabs && (
              <>
                <Menu.Item onClick={navigateToUpcomingClasses} icon={<IconCalendar size="s" />}>
                  Upcoming classes
                </Menu.Item>
                <Menu.Item onClick={navigateToPastClasses} icon={<IconList size="s" />}>
                  Past classes
                </Menu.Item>
              </>
            )}

            {shouldShowManageSubscription && (
              <Menu.Item
                onClick={() => push("/subscription/manage")}
                icon={<Repeat size={14} />}
                sx={{ whiteSpace: "nowrap" }}
                data-testid="manage-subscriptions"
              >
                Manage subscriptions
              </Menu.Item>
            )}
            <Menu.Item
              onClick={() => (window.location.href = "https://tutor-help.classdojo.com/hc/en-us")}
              icon={<IconHelpCircle size="s" />}
            >
              Help
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                helperWidgetAPI.openWidget({
                  email: sessionData?.user_details.email,
                  name: `${sessionData?.user_details.first_name || ""} ${sessionData?.user_details.last_name || ""}`,
                })
              }
              icon={<IconMail size="s" />}
            >
              Contact Us
            </Menu.Item>
            {shouldRenderAdditionalClassButton && (
              <Menu.Item
                onClick={() => {
                  dispatch({
                    type: actionTypes.UPDATE_BOOKING,
                    payload: null,
                  });
                  push("/additional-single-class/step-one");
                }}
                icon={<IconPlusCircle size="s" />}
              >
                Book a trial class
              </Menu.Item>
            )}
            <Divider />
            <Menu.Item
              onClick={() => logoutMutation.mutateAsync()}
              icon={<IconLogout size="s" data-name="logoutLink" />}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <Burger
        opened={navbarOpened || false}
        className={classes.burger}
        size="sm"
        onClick={toggleNavbar}
        title={burgerTitle}
        aria-label={burgerTitle}
      />
    </Box>
  );
};

export default ParentHeaderContents;

const NavTab = ({ copy, selected, handleClick }: { copy: string; selected: boolean; handleClick: () => void }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "column",
      borderBottom: selected ? marketplaceTheme.borders.dt_active : "none",
      justifyContent: "center",
      cursor: selected ? "default" : "pointer",
    }}
    onClick={selected ? () => {} : handleClick}
    // eslint-disable-next-line @web-monorepo/no-jsx-role-button
    role="button"
    tabIndex={0}
  >
    <div sx={{ paddingLeft: "m", paddingRight: "m" }}>
      <LabelText kind={selected ? "primary" : "secondary"} level={2}>
        {copy}
      </LabelText>
    </div>
  </div>
);
