import * as logClient from "@classdojo/log-client";
import "dialog-polyfill/dist/dialog-polyfill.css";
import { type ForwardedRef, type MutableRefObject, RefObject, forwardRef, useEffect, useRef } from "react";
import { useForwardedOrLocalRef } from "../../shared/hooks";
import classes from "./dialog.module.css";

type Props = React.DetailedHTMLProps<React.DialogHTMLAttributes<HTMLDialogElement>, HTMLDialogElement>;

type DialogProps = Props & {
  "data-name": string;
  "event-metadata"?: object;
  experiments?: string[];
  closeOnBackdropClick?: boolean;
  onBackdropClick?: () => void;
};

const CLOSED_FROM_BACKDROP_CLICK_TOKEN = "DDS:CLOSED_FROM_BACKDROP_CLICKED";

type DialogRef = ForwardedRef<HTMLDialogElement> | MutableRefObject<HTMLDialogElement>;
const getDialog = (ref: DialogRef): HTMLDialogElement | null => {
  if (!ref || !("current" in ref)) {
    return null;
  }

  return ref.current ?? null;
};

const useLogOpenEvent = (
  isOpen: boolean,
  {
    dataName,
    metadata,
    experiments,
  }: { dataName: string; metadata: object | undefined; experiments: string[] | undefined },
) => {
  const firstRun = useRef(true);
  const wasOpen = useRef(isOpen);
  useEffect(() => {
    if ((isOpen && firstRun) || (isOpen && !wasOpen.current)) {
      logClient.logEvent({
        eventName: `${logClient.getSite()}.modal.${dataName}.open`,
        automatedEvent: true,
        metadata,
        experiments,
      });
    }
    firstRun.current = false;
    wasOpen.current = isOpen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
};

const useLogOpenAndCloseEvent = (
  ref: ForwardedRef<HTMLDialogElement> | RefObject<HTMLDialogElement>,
  extras: { dataName: string; metadata: object | undefined; experiments: string[] | undefined },
) => {
  const isOpen = Boolean(getDialog(ref)?.open);
  useLogOpenEvent(isOpen, extras);

  useEffect(() => {
    const dialog = getDialog(ref);
    if (dialog) {
      const { dataName, experiments, metadata } = extras;
      const callback = () => {
        const didCloseFromBackdrop = dialog.returnValue === CLOSED_FROM_BACKDROP_CLICK_TOKEN;
        logClient.logEvent({
          eventName: `${logClient.getSite()}.modal.${dataName}.close`,
          automatedEvent: true,
          metadata: {
            ...(metadata ?? {}),
            didCloseFromBackdrop,
          },
          experiments,
        });
      };

      dialog.addEventListener("close", callback);
      return () => dialog.removeEventListener("close", callback);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

/**
 * Ensures dialogs are polyfilled
 * see: https://sentry.internal.classdojo-ops.com/organizations/classdojo/issues/?query=is:unresolved+close+is+not+a+function&statsPeriod=14d
 *
 * use showModal() to show the dialog with a backdrop
 * use show() to show the dialog WITHOUT a backdrop
 */
export const DDSDialog = forwardRef<HTMLDialogElement, DialogProps>(
  (
    {
      children,
      closeOnBackdropClick,
      onBackdropClick,
      "data-name": dataName,
      "event-metadata": metadata,
      experiments,
      ...props
    },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    useLogOpenAndCloseEvent(ref, { dataName, metadata, experiments });

    useEffect(() => {
      const dialog = getDialog(ref);
      if (!dialog) {
        return;
      }

      if (!("close" in dialog)) {
        import("dialog-polyfill").then(({ default: polyfill }) => {
          polyfill.registerDialog(dialog);
        });
      }
    }, [ref]);

    const _onBackdropClick = () => {
      onBackdropClick?.();
      if (closeOnBackdropClick) {
        getDialog(ref)?.close(CLOSED_FROM_BACKDROP_CLICK_TOKEN);
        logClient.logEvent({
          eventName: `${logClient.getSite()}.modal.${dataName}.backgroundClick.close`,
          automatedEvent: true,
          metadata,
          experiments,
        });
      }
    };

    const BaseDialog = (
      <dialog
        ref={ref}
        onMouseDown={(event) => {
          if (event.target === event.currentTarget) {
            _onBackdropClick();
          }
        }}
        className={classes.dialog}
        {...props}
      >
        {children}
      </dialog>
    );

    return BaseDialog;
  },
);
